(function () {
  'use strict';

  /* @ngdoc object
   * @name entitats
   * @description
   *
   */
  angular
      .module('neo.home.entitats', [
        'ui.router',
        'neo.home.entitats.entitat',
        'neo.home.entitats.newEntitat'
      ]);
}());
